<template lang="pug">
	header.header
		.content
			.logo_area
				.img_area
					img.logo(src="../assets/img/logo.png")
				.title Answered!
			.LanSwitch
				el-dropdown(@command="handleCommand")
					span.el-dropdown-link
						| {{command}}
						i.el-icon-arrow-down.el-icon--right
					el-dropdown-menu(slot="dropdown")
						el-dropdown-item(command="Eng") Eng
						el-dropdown-item(command="Indonesia") Indonesia
				.google_img(@click="download('download_googleplay_head_click')")
					.google
						img(src="../assets/img/google.png")
</template>
<script>
export default {
	data(){
		return{
      downloadUrl: 'https://answered.onelink.me/DJB9/a15710c3',
		}
	},
	methods:{
		handleCommand(command){
      window.gtag('event', 'language_switch')
			this.command = command
		},
		download(eventType) {
      window.gtag('event', eventType)
      const a = document.createElement('a')
      a.href = this.downloadUrl
      a.click()
    },
	},
	watch:{
		command:function(){
			this.$emit('func',this.command)
		}
	},
	props:['command'],
}
</script>
<style lang="stylus" scoped>
.header
	display flex
	align-items center
	top 0
	height 128px
	margin 0 auto
	align-items center
	z-index 999
	width 100%
	.content
		width 1157px
		margin 0px auto
		position relative
		display flex
		align-items center
		.logo_area
			display flex
			align-items center
			.img_area
				width 48px
				height 48px
				display inline-block
				img
					width 100%
			.title
				font-family:Roboto-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 36px;
				color: #111111;
				display inline-block
				margin-left 15px
		.LanSwitch
			position absolute
			right 0
			.el-dropdown
				display inline-block
				vertical-align middle
				span
					font-family Roboto
			.google_img
				width 189px
				height 56px
				cursor pointer
				display inline-block
				vertical-align middle
				margin-left 24px
				img
					width 100%
.el-dropdown-link
	font-family: SourceHanSansSC-Medium;
	font-size: 16px;
	line-height: 24px;
	color: #1E1E20;
	cursor: pointer;
</style>