<template lang="pug">
  .home-page(:class="[lang]")
    section.section.first-part
      header-tab(@func="getCommand" :command="command")
      .search
        .title(v-html="$TT('Search ang get')")
        .sub_title(v-html="$TT('unlock all solutions')")
        input.input(:placeholder="$TT('placeholder')" maxlength='150' v-model='inputValue' @keyup.enter="searchQuestion('homepage_search_enter')")
        .search-icon(@click="searchQuestion('homepage_search_enter')")
          img(src="../assets/img/search.png")
      div.partOne
        div.consent
          div.content
            .title(v-html="$TT('SNAP AND GET')")
            .sub_title(v-html="$TT('ANSWERS')")
            .details(v-html="$TT('details')")
            div(@click="download('download_googleplay_body_click')")
              div.google
                img(src="../assets/img/google.png")
            div.welcome
              img(src="../assets/img/welcome.png")
            div.searchOne
            div.searchTwo
    section.section
      div.partTwo
      div.partthree
          div.content
            .title(v-html="$TT('p3_title')")
            .sub_title(v-html="$TT('p3_sub_title')")
            .smalltriangle
              img(src="../assets/img/Smalltriangle.png")
            .number 3,000,000
            .questions(v-html="$TT('questions')")
          div.texture
      div.partfour
        .content-one
          .pictOne
            .title(v-html="$TT('pictOne_title')")
            .sub_title(v-html="$TT('pictOne_sub_title')")
            .pic
              img(src="../assets/img/picone.png")
        .content-two
          .pictTwo
              img(src="../assets/img/phone_google.png")
              .small_man
                img(src="../assets/img/small_man.png")
              .title(v-html="$TT('pictTwo_title')")
              .sub_title(v-html="$TT('pictTwo_sub_title')")
              .searchbox
                img(src="../assets/img/searchbox.png")
        .content-three
          .pictThree
            .title(v-html="$TT('pictThree_title')")
            .sub_title(v-html="$TT('pictThree_sub_title')")
            .small_woman
            .comments
              .review
                .Headportrait
                  img(src="../assets/img/Headportrait.png")
                .name(v-html="$TT('nameone')")
                .score
                  img(src="../assets/img/score.png")
                .time
                  img(src="../assets/img/time.png")
                .details(v-html="$TT('contentone')")
              .review
                .Headportrait
                  img(src="../assets/img/Headportraittwo.png")
                .name(v-html="$TT('nametwo')")
                .score
                  img(src="../assets/img/score5.0.png")
                .time
                  img(src="../assets/img/time.png")
                .details(v-html="$TT('contenttwo')")
              .review
                .Headportrait
                  img(src="../assets/img/Headportraitthree.png")
                .name(v-html="$TT('namethree')")
                .score
                  img(src="../assets/img/score.png")
                .time
                  img(src="../assets/img/timetwo.png")
                .details(v-html="$TT('contentthree')")
        .content-four
          .pictFour
            .card(@click="seeOther('homepage_otherquestion_click')")
              img(src="../assets/img/card.png")
            img.small-man(src="../assets/img/contentFourMan.png")
            .title(v-html="$TT('New quetions')")
            .sub_title(v-html="$TT('See other asked')" @click="seeOther('homepage_otherquestion_click')")
      div.footer
        .content
          .titlecaln
            .logo_area
              .img_area
                img(src="../assets/img/logo.png")
              .title Answered!
            .sub_title(v-html="$TT('forfree')")
          div(@click="download('download_googleplay_foot_click')")
            div.google
              img(src="../assets/img/google.png")
            div.ownership Copyright 2021, Talent Me Technology Pte. Ltd. All Rights Reserved.
</template>

<script>
import HeaderTab from "@/components/Header"
export default {
  name: 'Home',
  metaInfo() {
    return {
      title: 'Answered!',
    }
  },
  data() {
    return {
      lang: 'en',
      command:'Eng',
      downloadUrl: 'https://answered.onelink.me/DJB9/a15710c3',
      inputValue: '',
    }
  },
  components:{HeaderTab},
  created() {
    this.lang = this.$route.query.lang || 'en'
    this.$setLang(this.lang)
    window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
    window.gtag('event', 'official_homepage_show')
  },
  watch:{
    command:function(){
        if(this.command == 'Eng' && this.$route.query.lang !== 'en'){
          this.lang = 'en'
          this.$router.push('/en')
          this.$setLang(this.lang)
        }else if(this.command == 'Indonesia' && this.$route.query.lang !== 'id'){
          this.lang = 'id'
          this.$router.push('/id')
          this.$setLang(this.lang)
        }
    },
    lang:function(){
      window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
      window.gtag('event', 'language_switch')
      if(this.lang == 'en'){
          this.command = 'Eng'
      }else if(this.lang == "id"){
        this.command = "Indonesia"
      }
    },
  },
  methods:{
    getCommand(data){
      this.command = data
    },
    download(eventType) {
      window.gtag('event', eventType)
      const a = document.createElement('a')
      a.href = this.downloadUrl
      a.click()
    },
    searchQuestion(eventType) {
      window.gtag('event', eventType)
      this.$router.push({
        path: '/quesList',
        query: {
          lang: this.lang,
          inputValue: this.inputValue
        }
      })
    },
    seeOther(eventType) {
      window.gtag('event', eventType)
      this.$router.push({
        path: '/quesList',
        query: {
          lang: this.lang,
          inputValue: '',
        }
      })
    }
  },
}
</script>

<style lang="stylus">
.home-page
  min-width 1200px
  .first-part
    background linear-gradient(180deg, #FFF0E6 0%, #FCFCFC 100%)
    .search
      width 1157px
      margin 0 auto
      position relative
      .title
        margin 82px auto 0
        height 50px
        line-height 50px
        font-family:Roboto-bold
        font-size 60px
        text-align center
        color #111111
      .sub_title
        margin 16px auto 0
        height 50px
        line-height 50px
        font-size 36px
        text-align center
        color #666666
        position relative
        left 19px
      .input
        width 1017px
        height 80px
        background #FFFFFF
        border-radius 16px
        font-family Roboto
        font-size 24px
        line-height 28px
        margin 54px auto 138px
        padding-left 40px
        padding-right 100px
        color #999999
        outline none
      .search-icon
        width 32.5px
        height 32.5px
        cursor pointer
        position absolute
        top 194px
        right 40.5px
        img
          width 100%
  .section
    font-family:Roboto
    .partOne
      width 100%
      height 608px
      min-width: 1157px;
      .consent
        overflow visible
        width 1157px
        height 100%
        margin 0 auto
        .content
          width 100%
          height 100%
          overflow visible
          padding 0.1px
          position relative
          .title
            width 360px
            margin-top 30px
            font-family:Roboto-Black;
            font-size: 64px;
            line-height: 72px;
            color: #111100;
            span
              font-family:Roboto-Light;
          .sub_title
            width 360px
            font-size: 64px;
            font-family:Roboto-Black;
            font-weight:bold
            line-height: 72px;
            color #FF8308
          .details
            margin-top 24px
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.1px;
            font-family:Roboto-Regular;
            color: #111100;
          .google
            width 189px
            height 56px
            cursor pointer
            margin-top 72px
            img
              width 100%
          .welcome
            width 369.99px
            height 609.81px
            position absolute
            top 106.19px
            right 376.41px
            img
              width 100%
          .searchOne
            width 311.96px
            height 341px
            position absolute
            top 0px
            right 0px
            background: url("../assets/img/en/searchResults1.png") 0 0 / 100% 100%
          .searchTwo
            width 311.96px
            height 228px
            position absolute
            right 0px
            bottom 0px
            background: url("../assets/img/en/searchResults2.png") 0 0 / 100% 100%
    .partTwo
      width 100%
      min-width: 1157px;
      height 200px
      background: linear-gradient(180deg, #EBEBED 0%, rgba(247, 247, 247, 0.0001) 100%);
    .partthree
      width 100%
      height 220px
      background url(../assets/img/partthreebg.png)
      overflow hidden
      position relative
      .content
        width 1157px
        margin 38px auto
        height 143px
        position relative
        background url(../assets/img/scanning.png) 0 0 / 100% 100%
        .title
          font-weight: bold;
          font-family:Roboto-Black;
          font-size: 24px;
          line-height: 28px;
          color: #FFFFFF;
          position absolute
          top 45px
          left 36.91px
        .sub_title
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1px;
          font-family:Roboto-Regular;
          color: #FFFFFF;
          position absolute
          top 81px
          left 36.91px
        .smalltriangle
          width 17px
          height 13px
          position absolute
          top 66px
          left 753px
          img
            width 100%
        .number
          font-size: 50px;
          line-height: 59px;
          letter-spacing: 0.142857px;
          font-family:Roboto-Black;
          color: #FFFFFF;
          position absolute
          left 786px
          top 43px
        .questions
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.0571429px;
          font-family:Roboto-Regular;
          color: #FFFFFF;
          position absolute
          top 63px
          left 1029px
      .texture
        width 551px
        height 205px
        background url(../assets/img/texture.png) 0 0 / 100% 100%
        position absolute
        top 5px
        bottom 10px
        left 54.02%
    .partfour
      width 100%
      min-width: 1157px;
      .content-two, .content-four
        background #F8FAFD
      .pictOne
        width 1157px
        height 408px
        position relative
        margin 104px auto
        .title
          width 437px
          height 88px
          font-size: 40px;
          line-height: 44px;
          font-family:Roboto-Black;
          color: #111100;
          position absolute
          top 125px
          span
            color #FF8308
        .sub_title
          width 418px
          height 48px
          font-size: 18px;
          font-family:Roboto-Regular;
          line-height: 27px;
          letter-spacing: 0.1px;
          color: #111100;
          position absolute
          top 229px
        .pic
          width 474.42px
          height 408px
          position absolute
          right 7px
          img
            width 100%
      .pictTwo
        width 1157px
        height 615px
        margin 103px auto 0
        position relative
        img
          width 100%
          height 100%
        .small_man
          width 125.54px
          height 110px
          position absolute
          top 110.94px
          left 737px
          img
            wdith 100%
            height 100%
        .title
          width 430px
          height 88px
          font-size: 40px;
          line-height: 44px;
          color: #111100;
          font-family:Roboto-Black;
          position absolute
          top 238px
          left 737px
          span
            color #FF8308
        .sub_title
          width 470px
          font-size: 18px;
          font-family:Roboto-Regular;
          line-height: 27px;
          letter-spacing: 0.1px;
          color: #111100;
          position absolute
          top 342px
          left 737px
        .searchbox
          width 215px
          height 43px
          position absolute
          top 422px
          left 737px
          img
            width 100%
            height 100%
      .pictThree
        width 1157px
        height 436px
        margin 89px auto 90px
        position relative
        border-bottom 1px solid #E6E6E6
        .title
          width 156px
          height 88px
          font-weight: 900;
          font-size: 40px;
          line-height: 48px;
          color: #111100;
          font-family:Roboto-Black;
          position absolute
          top 0px
          left 0px
          span
            color #FF8308
        .sub_title
          width 200px
          height 24px
          font-size: 18px;
          font-family:Roboto-Regular;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: #111100;
          position absolute
          top 104px
          left 0px
        .small_woman
          width 158.82px
          height 174.94px
          background url(../assets/img/smallwoman.png) 0 0 / 100% 100%
          position absolute
          left 0px
          bottom 0px
          img
            width 100%
            height 100%
        .comments
          width 946px
          height 100%
          position absolute
          top 0px
          right 0px
          .review
            width 100%
            height 33.3%
            position relative
            border-top 1px solid #E6E6E6
            .Headportrait
              width 64px
              height 64px
              position absolute
              top 41px
              left 40px
              img
                width 100%
                height 100%
            .name
              font-weight: bold;
              font-size: 18px;
              line-height: 21px;
              color: #111100;
              font-family:Roboto-Bold;
              position absolute
              top 35px
              left 149px
            .score
              width 122px
              height 24px
              position absolute
              top 61px
              left 149px
              img
                width 100%
                height 100%
            .time
              width 93px
              height 20px
              position absolute
              top 91px
              left 149px
              img
                width 100%
                height 100%
            .details
              width 520px
              height 78px
              font-family:Roboto-Regular;
              position absolute
              top 35px
              right 0px
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.0875px;
              color: #111100;
      .pictFour
        width 1157px
        height 615px
        margin 0 auto
        position relative
        .card
          width 623px
          height 357px
          position absolute
          top 129px
          cursor pointer
          img
            width 100%
        .small-man
          width 140px
          height 106.12px
          position absolute
          top 130px
          left 791px
        .title
          width 361px
          height 132px
          font-family Roboto-Black
          font-size 40px
          line-height 44px
          position absolute
          left 791px
          top 253px
          span
            color #FF6B08
        .sub_title
          width 340px
          height 60px
          border 2px solid #FF6B08
          border-radius 12px
          font-size 24px
          text-align center
          vertical-align middle
          line-height 60px
          color #FF6B08
          letter-spacing: 0.1px
          position absolute
          left 791px
          top 417px
          cursor pointer
    .footer
      width 100%
      min-width: 1157px;
      height 212px
      background #222626
      .content
        width 1156px
        height 100%
        margin 0 auto
        position relative
        display flex
        .titlecaln
          width 700px
          height 97px
          position absolute
          top 67px
          .logo_area
            width 165px
            height 38.21px
            display flex
            .img_area
              width 38.21px
              height 38.21px
              img
                  width 100%
            .title
              margin-left 16.19px
              font-size: 20px;
              line-height: 23px;
              color: #FFFFFF;
              font-family:Roboto-Bold;
              display flex
              align-items center
          .sub_title
            margin-top 16.79px
            font-size: 32px;
            line-height: 37px;
            color: #FFFFFF;
            font-family:Roboto-Black;
            span
              color #FF8308
        .google
          width 160px
          height 47.41px
          cursor pointer
          position absolute
          right 0
          top 67px
          img
            width 100%
        .ownership
          font-family: Roboto-Regular;
          font-size: 12px;
          line-height: 14px;
          color: #646767;
          position: absolute
          right 0
          top 146px
.id
  .partOne
    .content
      .searchOne
        background-image: url(../assets/img/id/searchResults1.png)
      .searchTwo
        background-image: url(../assets/img/id/searchResults2.png)
  .partfour
    .content
      .pictTwo
        .searchbox
          top 474px
</style>
